.searchAligment {
    text-align: right;
}

.searchInput {
    margin-left: 5px;
}

.iconsStyle {
    margin-left: 5px;
}

.sortCursor {
    cursor: pointer;
}

.modalButton {
    background-color: white;
    border: 1px solid lightgrey;
    font-size: 14px
}

.spanButton {
    margin-right: 5px
}



.modalStyle {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-bottom: 3px solid #d2d6de;
    margin-bottom: 30px;
    width: 100%;
    padding-bottom: 10px;
  }

.buttonsDivModal {
    text-align: right;
    margin-top: 30px;
}

.buttonRightModal {
    margin-left: 5px;
}

.addMemberMargin {
    margin-top: 5px;
}

.addContent {
    width: 50%  
}

.bioArea {
    width: 100%;
    height: 200px;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #dddddd;
    padding: 10px
}

.contactForm {
    width: 50%;
    margin-left: 25%;
}

.selectStyle {
    padding-top: 3px
}

.newsSearch {
    text-align: right;
    margin-left: -10px;
}

.selectCategory {
    width: 40%;
    text-align: left;
    margin-left: -5px;
}

.textEditorPadding {
    padding-top: 10px;
}

.textEditor {
    min-height: 280px;
}

.textAreaNews {
    margin-top: 5px;
    width: 100%;
    height: 330px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.addNews {
    margin: 10px;
}

.mainContent {
    padding-top: 10px;
    padding-left: 40px;
}

.pgTitle{
    margin-left: 15px;
}

.login {
    /* padding-top:4.2rem;
    padding-bottom:4.2rem;
    background-repeat: no-repeat; */
    /* padding-right: 50%; */
    /* margin-right: 50%; */
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 5%;
    /* margin-bottom: 10%; */
    /* background-image: url('D:/stkomora-admin/src/assets/img/wall2.jpg'); */
    /* background-image: url('D:/stkomora-admin/src/assets/img/wall1.png'); */
    width: 100%;
    height: auto;
}

.bimg {
    /* background-image: url('D:/stkomora-admin/src/assets/img/wall1.png'); */
}
    a{
     text-decoration:none !important;
     }
     h1,h2,h3{
     font-family: 'Kaushan Script', cursive;
     }
      .myform{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding: 1rem;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 1.1rem;
    outline: 0;
    max-width: 500px;
     }
     .tx-tfm{
     text-transform:uppercase;
     }
     .mybtn{
     border-radius:50px;
     }
    
     .login-or {
     position: relative;
     color: #aaa;
     margin-top: 10px;
     margin-bottom: 10px;
     padding-top: 10px;
     padding-bottom: 10px;
     }
     .span-or {
     display: block;
     position: absolute;
     left: 50%;
     top: -2px;
     margin-left: -25px;
     background-color: #fff;
     width: 50px;
     text-align: center;
     }
     .hr-or {
     height: 1px;
     margin-top: 0px !important;
     margin-bottom: 0px !important;
     }
     .google {
     color:#666;
     width:100%;
     height:40px;
     text-align:center;
     outline:none;
     border: 1px solid lightgrey;
     }
      form .error {
     color: #ff0000;
     }
     #second{display:none;}